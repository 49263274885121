/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/lazysizes@5.3.2/plugins/bgset/ls.bgset.min.js
 * - /npm/lazysizes@5.3.2/plugins/print/ls.print.min.js
 * - /npm/lazysizes@5.3.2/plugins/respimg/ls.respimg.min.js
 * - /npm/lazysizes@5.3.2/plugins/unveilhooks/ls.unveilhooks.min.js
 * - /npm/lazysizes@5.3.2/lazysizes.min.js
 * - /npm/scrollprogress@3.0.2/dist/scrollProgress.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
